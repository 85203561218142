import React from "react";
import {Stack, Typography} from "@mui/material";
import {
  getStorageUnitClient,
  getWarehouseAreaClient,
  getWarehouseClient,
} from "../../api";
import {useLoaderData} from "react-router-dom";
import {LoaderFunctionArgs} from "@remix-run/router/utils";
import {WarehouseAreaWithWarehouse} from "./models";
import {WarehouseArea} from "./WarehouseArea";

interface Data {
  warehouseAreas: WarehouseAreaWithWarehouse[];
}

const loader = async ({params}: LoaderFunctionArgs): Promise<Data> => {
  const {organizationId} = params;
  if (!organizationId) {
    throw new Error("Unknown organizationId");
  }

  const warehouseAreaClient = getWarehouseAreaClient();
  const warehouseClient = getWarehouseClient();
  const storageUnitClient = getStorageUnitClient();

  const warehouseAreasPromise = warehouseAreaClient.getAll(organizationId);
  const warehousesPromise = warehouseClient.getAll(organizationId);
  const storageUnitsPromise = storageUnitClient.getAll(organizationId);
  const warehouseAreas = await warehouseAreasPromise;
  const warehouses = await warehousesPromise;
  const storageUnits = await storageUnitsPromise;

  const warehouseAreasWithChildren = warehouseAreas.map(
    area => ({
      ...area,
      warehouses: warehouses
        .filter(warehouse => warehouse.warehouseAreaId === area.id)
        .map(warehouse => ({
          ...warehouse,
          storageUnits: storageUnits.filter(storageUnit => storageUnit.warehouseId === warehouse.id)
        }))
    })
  );

  return {warehouseAreas: warehouseAreasWithChildren};
};

const Page: React.FC = () => {
  const {warehouseAreas} = useLoaderData() as Data;

  return (
    <Stack spacing={1}>
      <Typography variant="h5">Stocks</Typography>
      <Stack spacing={2}>
        {warehouseAreas.map(warehouseArea => <WarehouseArea key={warehouseArea.id} warehouseArea={warehouseArea}/>)}
      </Stack>
    </Stack>
  );
};

export const stocks = {
  loader,
  element: <Page/>
};
