import React from "react";
import {WarehouseAreaWithWarehouse} from "../models";
import {Accordion, AccordionDetails, AccordionSummary, Box, Stack, useTheme} from "@mui/material";
import {Warehouse} from "../Warehouse";
import {Header} from "./Header";

interface WarehouseAreaProps {
  warehouseArea: WarehouseAreaWithWarehouse
}

export const WarehouseArea: React.FC<WarehouseAreaProps> = ({warehouseArea}) => {
  const theme = useTheme();
  const border = `1px solid ${theme.palette.divider}`;
  return (
    <Box>
      <Accordion
        elevation={0}
        sx={{
          border: border,
          borderRadius: "4px"
        }}
      >
        <AccordionSummary
          sx={{
            "& .MuiAccordionSummary-content": {
              marginY: theme.spacing(1),
            },
            "& .MuiAccordionSummary-content.Mui-expanded": {
              marginY: theme.spacing(1),
            }
          }}
        >
          <Header warehouseArea={warehouseArea}/>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            borderTop: border,
            backgroundColor: "#f2f2f2"
          }}
        >
          <Stack spacing={3}>
            {warehouseArea.warehouses.map(warehouse => <Warehouse key={warehouse.id} warehouse={warehouse}/>)}
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
