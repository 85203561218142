import React from "react";
import {Stack} from "@mui/material";
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import {Caption} from "./Caption";

interface CertifiedProps {
  isCertified: boolean
}

export const Certified: React.FC<CertifiedProps> = ({isCertified}) => {
  if (!isCertified) {
    return null;
  }
  return (
    <Stack direction="row" alignItems="center">
      <Caption color="primary">Certified</Caption>
      <VerifiedUserOutlinedIcon color="primary"/>
    </Stack>
  );
};
