import React, {useMemo} from "react";
import {Typography} from "@mui/material";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";
import {grey} from "@mui/material/colors";

interface HarmonizedSystemCodeProps {
  code: string;
}

export const HarmonizedSystemCode: React.FC<HarmonizedSystemCodeProps> = ({code}) => {
  const parts = useMemo(() => [
    code.slice(0, 2),
    code.slice(2, 4),
    code.slice(4, 6),
    code.slice(6, 8),
    code.slice(8, 10),
    code.slice(10)
  ], [code]);
  return (
    <>
      <CodePart code={parts[0]} color={colors[0]}/>
      <CodePart code={parts[1]} color={colors[1]}/>
      <CodePart code={parts[2]} color={colors[2]}/>
      <CodePart code={parts[3]} color={colors[3]}/>
      <CodePart code={parts[4]} color={colors[4]}/>
      <CodePart code={parts[5]} color={colors[5]}/>
    </>
  );
};

interface CodePartProps {
  code: string;
  color: string;
}

const CodePart: React.FC<CodePartProps> = ({code, color}) => {
  const style: SxProps<Theme> = {
    display: "inline-block",
    padding: "0px 2px",
    lineHeight: "16px",
    color: "white",
    textAlign: "center",
    backgroundColor: color,
  };
  return (
    <Typography sx={style} variant="caption" component="span">
      {code}
    </Typography>
  );
};

const colors = [
  "#ec7357",
  "#bb6bd9",
  "#2d9cdb",
  "#f2994a",
  "#a761ff",
  grey[600]
];
