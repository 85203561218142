import React from "react";
import {DecimalWithUnit} from "../../../api";
import {Caption} from "./Caption";
import {Box, Divider, Stack, Typography, useTheme} from "@mui/material";

interface HarvestCardProps {
  amount: DecimalWithUnit;
  harvestYear: number;
  storageType: string;
}

export const HarvestCard: React.FC<HarvestCardProps> = ({amount, harvestYear, storageType}) => {
  const theme = useTheme();
  return (
    <Box sx={{
      paddingX: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
    }}>
      <Caption>Harvest</Caption>
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem/>}
        spacing={1}
      >
        <Typography variant="body2">{amount.value} {amount.unit}</Typography>
        <Typography variant="body2">{harvestYear}</Typography>
        <Typography variant="body2">{storageType}</Typography>
      </Stack>
    </Box>
  );
};
