import {
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Typography,
  useTheme,
} from "@mui/material";

import React from "react";
import {
  forwardRef,
  useMemo
} from "react";

import {
  NavLink,
  NavLinkProps,
  useMatch
} from "react-router-dom";
import {OverridableComponent} from "@mui/material/OverridableComponent";
import {SvgIconTypeMap} from "@mui/material/SvgIcon/SvgIcon";
import HomeIcon from "@mui/icons-material/Home";
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';

export const NavigationMenu: React.FC = () => {
  const theme = useTheme();

  return (
    <MenuList sx={{margin: theme.spacing(1)}}>
      <MenuLink path="/" text="Home" icon={HomeIcon}/>
      <Divider/>
      <MenuLink path="/organizations/00000000-0000-0000-0000-000000000000/stocks" text="Stocks" icon={DashboardOutlinedIcon}/>
    </MenuList>
  );
};

interface MenuLinkProps {
  path: string;
  text: string;
  icon?: OverridableComponent<SvgIconTypeMap>
}

const MenuLink: React.FC<MenuLinkProps> = (props) => {
  const {icon: IconComponent, text, path} = props;

  const renderLink = useMemo(
    () => forwardRef<HTMLAnchorElement, Omit<NavLinkProps, "to">>(
      (
        itemProps,
        ref,
      ) => {
        return <NavLink to={path} ref={ref} {...itemProps}/>;
      }),
    [path],
  );

  const match = useMatch(path);

  const color = match === null ? "inherit" : "primary";

  return (
    <MenuItem
      component={renderLink}
      selected={match !== null}
    >
      {
        IconComponent
          ? (
            <ListItemIcon>
              <IconComponent color={color}/>
            </ListItemIcon>
          )
          : null
      }
      <ListItemText>
        <Typography color={color}>{text}</Typography>
      </ListItemText>
    </MenuItem>
  );
};
