import {Box, Drawer, useTheme} from "@mui/material";
import {Logo} from "./Logo";
import {NavigationMenu} from "./NavigationMenu";
import {Outlet} from "react-router-dom";
import React from "react";

const Page = () => {
  const theme = useTheme();

  const drawerWidth = 240;
  const backgroundColor = theme.palette.background.default;

  return (
    <Box sx={{display: "flex"}}>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          }
        }}
        PaperProps={{
          sx: {
            borderWidth: 0,
            backgroundColor: backgroundColor,
          }
        }}
        variant="permanent"
        anchor="left"
      >
        <Logo/>
        <NavigationMenu/>
      </Drawer>
      <Box sx={{
        flexGrow: 1,
        backgroundColor: backgroundColor,
        padding: theme.spacing(),
      }}>
        <Outlet/>
      </Box>
    </Box>
  );
};

export const root = {
  element: <Page/>
};
