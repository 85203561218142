import {AxiosInstance} from "axios";
import {getAxios} from "./axios";

export interface ClientSettings {
  getAxios: () => AxiosInstance;
}

export const defaultClientSettings: ClientSettings = {
  getAxios
};

export class ClientBase {
  private axios: AxiosInstance;

  constructor(settings?: ClientSettings) {
    settings = settings ?? defaultClientSettings;
    this.axios = settings.getAxios();
  }

  protected get<TResponse>(uri: string): Promise<TResponse> {
    return this.axios.get<TResponse>(uri).then(response => response.data);
  }
}
