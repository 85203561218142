import {createTheme} from '@mui/material/styles';

const bodyBackground = "#f9f9f9";
const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#4caf50"
    },
    background: {
      default: bodyBackground,
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: bodyBackground
        }
      }
    }
  }
});

export default theme;
