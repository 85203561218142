import React from "react";
import {Card, Divider} from "@mui/material";
import {StorageUnit as StorageUnitModel} from "../../../api";
import {ProductCard} from "./ProductCard";
import {SkuCard} from "./SkuCard";
import {HarvestCard} from "./HarvestCard";

interface StorageUnitProps {
  storageUnit: StorageUnitModel
}

export const StorageUnit: React.FC<StorageUnitProps> = ({storageUnit}) => {
  return (
    <Card sx={{width: "336px"}}>
      <ProductCard {...storageUnit}/>
      <SkuCard {...storageUnit}/>
      <Divider/>
      <HarvestCard {...storageUnit}/>
    </Card>
  );
};
