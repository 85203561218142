import * as React from 'react';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import * as pages from "./pages";

type Middleware = (children: JSX.Element) => JSX.Element;

const App = () => {

  const router = createBrowserRouter([
    {
      path: "/",
      ...pages.root,
      children: [
        {
          path: "organizations/:organizationId",
          children: [
            {
              path: "stocks",
              ...pages.stocks
            }
          ]
        }
      ]
    }
  ]);

  let app = (<RouterProvider router={router}/>);

  const middlewares: Middleware[] = [];

  for (const middleware of middlewares.reverse()) {
    app = middleware(app);
  }

  return app;
};

export default App;
