import {ClientBase} from "./ClientBase";
import {Warehouse} from ".";

export class WarehouseClient extends ClientBase {
  getAll(organizationId: string) {
    return this.get<Warehouse[]>(`api/organizations/${organizationId}/warehouses`);
  }
}

let warehouseClient: WarehouseClient;
export const getWarehouseClient = () => warehouseClient ?? (warehouseClient = new WarehouseClient());
