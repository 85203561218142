import React from "react";
import "flag-icons/css/flag-icons.min.css";
import {useTheme} from "@mui/material";

interface CountryFlagProps {
  country: string;
}

export const CountryFlag: React.FC<CountryFlagProps> = ({country}) => {
  const theme = useTheme();
  return (
    <span
      style={{
        width: theme.spacing(2)
      }}
      className={"fi fi-" + country.toLowerCase()}
    />
  );
};
