import {ClientBase} from "./ClientBase";
import {StorageUnit} from ".";

export class StorageUnitClient extends ClientBase {
  getAll(organizationId: string) {
    return this.get<StorageUnit[]>(`api/organizations/${organizationId}/storage-units`);
  }
}

let storageUnitClient: StorageUnitClient;
export const getStorageUnitClient = () => storageUnitClient ?? (storageUnitClient = new StorageUnitClient());
