import {Typography} from "@mui/material";
import React from "react";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";

interface CaptionProps {
  children: string;
  color?: string;
  sx?: SxProps<Theme>;
}

export const Caption : React.FC<CaptionProps> = ({children, color, sx}) => {
  const style = {
    ...sx,
    lineHeight: "16px"
  };
  return <Typography sx={style} color={color} variant="caption">{children}</Typography>;
}
