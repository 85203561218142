import React from "react";
import {Box, Typography, useTheme} from "@mui/material";

import LogoImage from "./Logo.svg";

export const Logo: React.FC = () => {
  const theme = useTheme();
  return (
    <Box sx={{
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(7),
    }}>
      <img style={{display: "block", margin: "auto"}} height={64} src={LogoImage} alt=""/>
      <Typography sx={{textAlign: "center", fontSize: "20px"}}>Kolo.Track</Typography>
    </Box>
  );
}
