import React from "react";
import {WarehouseAreaWithWarehouse} from "../models";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import {Stack, Typography, useTheme} from "@mui/material";
import {CountryFlag} from "./CountryFlag";
import {grey} from '@mui/material/colors';

interface HeaderProps {
  warehouseArea: WarehouseAreaWithWarehouse;
}

export const Header: React.FC<HeaderProps> = ({warehouseArea}) => {
  const theme = useTheme();

  const {address} = warehouseArea;

  return (
    <Stack direction="row">
      <WarehouseIcon
        htmlColor={grey[600]}
        sx={{marginTop: theme.spacing(1)}}
      />
      <Stack
        sx={{
          marginLeft: theme.spacing(3),
        }}
      >
        <Stack
          direction="row"
          spacing={1}
        >
          <Typography variant="subtitle1">{address.streetAddress}, {address.city}</Typography>
          <CountryFlag country={address.country}/>
        </Stack>
        <Stack
          direction="row"
          divider={<Typography variant="caption">|</Typography>}
          spacing={1}
        >
          <HeaderField caption="Coordinates" value="123"/>
          <HeaderField caption="Warehouse#" value={warehouseArea.id}/>
        </Stack>
      </Stack>
    </Stack>
  );
};

interface HeaderFieldProps {
  caption: string;
  value: string;
}

const HeaderField: React.FC<HeaderFieldProps> = ({caption, value}) => {
  return (
    <Stack direction="row">
      <Typography variant="caption" color={grey[600]}>{caption}:&nbsp;</Typography>
      <Typography variant="caption">{value}</Typography>
    </Stack>
  );
};
