import React from "react";
import {Product} from "../../../api";
import {Box, Typography, useTheme} from "@mui/material";
import {HarmonizedSystemCode} from "./HarmonizedSystemCode";
import {Caption} from "./Caption";
import {Certified} from "./Certified";

interface ProductCardProps {
  product: Product;
  isCertified: boolean;
}

export const ProductCard: React.FC<ProductCardProps> = ({product, isCertified}) => {
  const theme = useTheme();
  return (
    <Box sx={{
      display: "grid",
      gridTemplateColumns: "minmax(0,1fr) auto",
      paddingX: theme.spacing(2),
      paddingY: theme.spacing(1),
    }}>
      <Caption sx={{gridRow: "1", gridColumn: "1"}}>Product</Caption>
      <Box sx={{gridRow: "2", gridColumn: "1"}}>
        <HarmonizedSystemCode code={product.code}/>
      </Box>
      <Box sx={{gridRow: "1/3", gridColumn: "2"}}>
        <Certified isCertified={isCertified}/>
      </Box>
      <Typography
        sx={{
          gridRow: "3",
          gridColumn: "1/3",
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: '2',
          WebkitBoxOrient: 'vertical',
        }}
        variant="body2">
        {product.name}
      </Typography>
    </Box>
  );
};
