import axios, {AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, RawAxiosRequestHeaders} from "axios";
import environment from "../environment";

let axiosInstance: AxiosInstance;
export const getAxios = (): AxiosInstance => axiosInstance ?? (axiosInstance = createAxios());

const createAxios = (): AxiosInstance => {
  const instance = axios.create({
    baseURL: environment.apiRoot
  });

  instance.interceptors.request.use(authenticateRequestInterceptor);
  instance.interceptors.response.use(undefined, refreshTokenAndRetryInterceptor);

  return instance;
}

const authenticateRequestInterceptor = async (request: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
  if (!request.headers) {
    request.headers = {};
  }

  const headers = request.headers as RawAxiosRequestHeaders;
  const token = await getAuthToken();
  headers["Authorization"] = "Bearer " + token.token;

  return request;
};

const refreshTokenAndRetryInterceptor = (error: any): Promise<AxiosResponse> => {
  if (!(error instanceof AxiosError)) {
    return Promise.reject(error);
  }
  if (!error.response || error.response.status !== 401) {
    return Promise.reject(error);
  }
  if (!error.config) {
    return Promise.reject(error);
  }
  const state = getRequestState(error.config);
  if (state.preventRetryOn401) {
    return Promise.reject(error);
  }
  state.preventRetryOn401 = true;
  refreshAuthToken();
  return getAxios().request(error.config);
};

interface KoloState {
  preventRetryOn401: boolean
}

const createEmptyState = (): KoloState => {
  return {
    preventRetryOn401: false
  };
}

const getRequestState = (request: AxiosRequestConfig): KoloState => {
  const requestAsAny = request as any;
  if (requestAsAny.koloState === undefined) {
    requestAsAny.koloState = createEmptyState()
  }
  return requestAsAny.koloState;
};

interface Token {
  token: string;
}

let authToken: Promise<Token>;
const getAuthToken = () => authToken ?? (authToken = fetchAuthToken());

const fetchAuthToken = async (): Promise<Token> => {
  const endpoints = await getEndpoints();
  const config: AxiosRequestConfig = {baseURL: endpoints.accounts, withCredentials: true};
  const state = getRequestState(config);
  state.preventRetryOn401 = true;
  const response = await axios.get<Token>("api/token", config);
  return response.data;
};

const refreshAuthToken = () => {
  authToken = fetchAuthToken();
};

interface Endpoints {
  api: string;
  accounts: string;
}

let endpoints: Promise<Endpoints>;
const getEndpoints = () => endpoints ?? (endpoints = fetchEndpoints());

const fetchEndpoints = async (): Promise<Endpoints> => {
  const response = await axios.get<Endpoints>("api/$endpoints", {baseURL: environment.apiRoot});
  return response.data;
};
