import React from "react";
import {WarehouseWithStorageUnits} from "../models";
import {Box, Typography, useTheme} from "@mui/material";
import {StorageUnit} from "../StorageUnit";

interface WarehouseProps {
  warehouse: WarehouseWithStorageUnits
}

export const Warehouse: React.FC<WarehouseProps> = ({warehouse}) => {
  const theme = useTheme();
  return (
    <Box>
      <Typography
        variant="subtitle2"
        sx={{
        }}
      >
        {warehouse.name}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: theme.spacing(3),
          marginTop: theme.spacing(1)
        }}
      >
        {warehouse.storageUnits.map(storageUnit => <StorageUnit key={storageUnit.id} storageUnit={storageUnit}/>)}
      </Box>
    </Box>
  );
};
