import {ClientBase} from "./ClientBase";
import {WarehouseArea} from ".";

export class WarehouseAreaClient extends ClientBase {
  getAll(organizationId: string) {
    return this.get<WarehouseArea[]>(`api/organizations/${organizationId}/warehouse-areas`);
  }
}

let warehouseAreaClient: WarehouseAreaClient;
export const getWarehouseAreaClient = () => warehouseAreaClient ?? (warehouseAreaClient = new WarehouseAreaClient());
