import React from "react";
import {Box, Typography, useTheme} from "@mui/material";
import {Caption} from "./Caption";

interface SkuCardProps {
  sku: string;
}

export const SkuCard: React.FC<SkuCardProps> = ({sku}) => {
  const theme = useTheme();
  return (
    <Box sx={{
      paddingX: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
    }}>
      <Caption>SKU</Caption>
      <Typography variant="body2">{sku}</Typography>
    </Box>
  );
};
